import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';

export const DATE_FORMAT_CONFIG = [
  {
    provide: DATE_PIPE_DEFAULT_OPTIONS,
    useValue: { dateFormat: 'dd/MM/YYYY HH:mm:ss', timezone: '+0000' },
  },
  { provide: MAT_DATE_LOCALE, useValue: 'fr' },
  provideMomentDateAdapter(),
];
